<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            @on-click="onClickTabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <Select v-model="carViolateType"
                      clearable
                      placeholder="违规类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in carViolateTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="carTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeCarNumber">
              </MyTreeSelect>
            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="personViolateType"
                      clearable
                      placeholder="违规类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in personViolateTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="personName"
                            placeholder="人员名称"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeName">
              </MyTreeSelect>
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        style="width:260px"></DatePicker>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/workViolations/query'])"
                    :ghost="buttonIndex == 0 ? false : true"
                    @click.stop="onClickCurrentMonth">本月</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/workViolations/query'])"
                    :ghost="buttonIndex == 1 ? false : true"
                    @click.stop="onClickLastMonth">上月</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/workViolations/query'])"
                    :ghost="buttonIndex == 2 ? false : true"
                    @click.stop="onClickCurrentWeek">本周</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/workViolations/query'])"
                    :ghost="buttonIndex == 3 ? false : true"
                    @click.stop="onClickNearlyThreeDay">近三天</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/workViolations/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/workViolations/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <Dropdown v-if="checkPermi(['/admin/workViolations/export'])">
        <Button class="m-r-10">
          <Icon custom="i-icon icon-shangchuan"
                size="16"></Icon>
          导出
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="part">
            <ExportTemplate :path="getExportPath"
                            :data="getExportData('part')">
              <Button>
                导出部分
              </Button>
            </ExportTemplate>
          </DropdownItem>
          <DropdownItem name="now">
            <ExportTemplate :path="getExportPath"
                            :data="getExportData('now')">
              <Button>
                导出本页
              </Button>
            </ExportTemplate>
          </DropdownItem>
          <DropdownItem name="all">

            <ExportTemplate :path="getExportPath"
                            :data="getExportData('all')">
              <Button>
                导出全部
              </Button>
            </ExportTemplate>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <!-- <Dropdown @on-click="onClickBatch">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiugai1"
                  size="16"></Icon>
            批量操作
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="handle">批量处理</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div> -->
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button type="text"
                    class="tableOperaBtn"
                    v-hasPermi="['/admin/workViolations/edit']"
                    @click.stop="onClickDeal(row)">处理</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <!-- 处理 -->
    <ViolationAlarmDealModal v-model="violationAlarmDealVisible"
                             :dataId.sync="violationAlarmDealId"
                             :type="violationAlarmDealType"
                             @onClickConfirm="getList" @onChange='onChangClearRowStyle'></ViolationAlarmDealModal>
    <!-- 处理记录 -->
    <ViolationAlarmDealListModal v-model="violationAlarmDealListVisible"
                                 :dataId.sync="violationAlarmDealListId"
                                 type="violations" @onChange='onChangClearRowStyle'></ViolationAlarmDealListModal>

    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat" @onChange='onChangClearRowStyle'></PositionDetailModal>
  </div>
</template>

<script>
import { getCurrentMonth, getCurrentWeek, getNearlyThreeDay, getLastMonth, } from '@/utils/dateRangUtil'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import ViolationAlarmDealModal from '@/components/product/admin/modal/patrolMaintain/ViolationAlarmDealModal'
import ViolationAlarmDealListModal from '@/components/product/admin/modal/patrolMaintain/ViolationAlarmDealListModal'
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal';
import { mapActions, mapGetters } from 'vuex';
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTreeSelect,
    ViolationAlarmDealModal,
    ViolationAlarmDealListModal,
    PositionDetailModal,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '车辆违规'
        },
        {
          name: '人员违规'
        },
      ],
      violationId: '',
      carNumber: '',
      carNumberId: '',
      personId: '',
      personName: '',
      carViolateType: '',
      carViolateTypeArr: [],
      personViolateType: '',
      personViolateTypeArr: [],
      date: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsCar: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carName',
          tooltip: true,
          width: 120,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '违规类型',
          key: 'ruleTypeName',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '发生地点',
          key: 'managePhone',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '违规时间',
          key: 'time',
          tooltip: true,
          width: 180,
          align: 'center'
        },
        {
          title: '内容',
          key: 'content',
          tooltip: true,
          minWidth: 200,
          align: 'center'
        },
        {
          title: '处理记录',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.processingRecord(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '处理方式',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '备注',
          key: 'dealRemark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80,
          align: 'center'
        }
      ],
      columnsPerson: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '人员名称',
          key: 'personName',
          tooltip: true,
          width: 120,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '违规类型',
          key: 'violationTypeName',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '发生地点',
          key: 'managePhone',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '违规时间',
          key: 'date',
          tooltip: true,
          width: 180,
          align: 'center'
        },
        {
          title: '内容',
          key: 'content',
          tooltip: true,
          minWidth: 200,
          align: 'center'
        },
        {
          title: '处理记录',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.processingRecord(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '处理方式',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '备注',
          key: 'dealRemark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 处理
      violationAlarmDealVisible: false,
      violationAlarmDealId: '',
      violationAlarmDealType: '',
      // 处理记录
      violationAlarmDealListVisible: false,
      violationAlarmDealListId: '',
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0
    };
  },
  watch: {
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.carViolationId == items.carViolationId
            })
            this.selectIndexArr.push(data.carViolationId)
          } else {
            let data = this.data.find((items, index) => {
              return item.dlbjViolationId == items.dlbjViolationId
            })
            this.selectIndexArr.push(data.dlbjViolationId)
          }
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.carViolationId == items.carViolationId
            })
            this.selectVisitedIndexArr.push(data.carViolationId)
          } else {
            let data = this.data.find((items, index) => {
              return item.dlbjViolationId == items.dlbjViolationId
            })
            this.selectVisitedIndexArr.push(data.dlbjViolationId)
          }
        })
      }
    }
  },

  computed: {
    ...mapGetters({
      'carTreeArr': 'getCarTreeList',
      'personTreeArr': 'getPersonTreeList'
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsCar
          break;
        case 1:
          columns = this.columnsPerson
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarViolations
          break;
        case 1:
          path = this.$http.exportPersonViolations
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTreeList',
      'updatePersonTreeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateCarTreeList()
      this.updatePersonTreeList()
      let arr = [{
        id: 0,
        name: '全部'
      }]
      this.$http.getWorkRuleItemList({ type: 3 }).then(res => {
        if (res.code === 200) {
          this.carViolateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.sysName
            item.name = item.sysName
            return item
          }))
        }
      })
      this.$http.getWorkRuleItemList({ type: 2 }).then(res => {
        if (res.code === 200) {
          this.personViolateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.sysName
            item.name = item.sysName
            return item
          }))
        }
      })
      if (this.$route.params.authorType) {
        let params = this.$route.params
        if (params.authorType === 'car') {
          this.violationId = params.id
          this.carViolateType = params.type
          this.carNumberId = params.authorId
          this.carNumber = params.author
        } else {
          this.currentTabIndex = 1
          this.violationId = params.id
          this.personViolateType = params.type
          this.personId = params.authorId
          this.personName = params.author
        }
      }
      this.getList()
    },
    // 点击切换tab
    onClickTabs () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 选择车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 选择姓名
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'user') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.personId = idArr.join()
      this.personName = valueArr.join()
    },
    // 排序
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.currentTabIndex === 0) {
        if (this.selectVisitedIndexArr.includes(row.carViolationId)) {
          return 'table-visited-bgcolor'
        }
      } else {
        if (this.selectVisitedIndexArr.includes(row.dlbjViolationId)) {
          return 'table-bgcolor'
        }
      }
      if (this.currentTabIndex === 0) {
        if (this.selectIndexArr.includes(row.carViolationId)) {
          return 'table-bgcolor'
        }
      } else {
        if (this.selectIndexArr.includes(row.dlbjViolationId)) {
          return 'table-bgcolor'
        }
      }

    },
    // 点击表格中的查看位置
    onClickAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
      this.selectVisitedArr.push(row)
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay()
      this.onClickSearch()
      this.buttonIndex = 3
    },
    // 本周
    onClickCurrentWeek () {
      this.date = getCurrentWeek()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      this.onClickSearch()
      this.buttonIndex = 1
    },
    onChangeDate (date) {
      this.date = date
      this.buttonIndex = null
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.carViolationId)
          break;
        case 1:
          this.selectArr = selection.map(item => item.dlbjViolationId)
          break;
        default:
          break;
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.violationId = ''
      // 车辆
      this.carNumber = ''
      this.carNumberId = ''
      this.carViolateType = ''
      // 人员
      this.personName = ''
      this.personId = ''
      this.personViolateType = ''
      this.date = []
      this.buttonIndex = null
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getCarViolationList()
          break;
        case 1:
          this.getPersonViolationList()
          break;
        default:
          break;
      }
    },
    // 车辆违规列表
    getCarViolationList () {
      let params = {
        carViolationId: this.violationId,
        ruleTypeName: this.carViolateType == 0 ? '' : this.carViolateType,
        // ruleTypeId: "",
        carTypeId: "",
        carTypeName: "",
        carId: this.carNumberId,
        startDate: this.date.length == 0 ? '' : this.date[0],
        endDate: this.date.length == 0 ? '' : this.date[1],
        gridId: "",
        page: this.pageNum,
        size: this.pageSize
      }
      this.tableLoading = true
      this.$http.getCarViolationList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 人员违规列表
    getPersonViolationList () {
      let params = {
        dlbjViolationId: this.violationId,
        personTypeId: "",
        personTypeName: "",
        personId: this.personId,
        // violationTypeId: "",
        violationTypeName: this.personViolateType == 0 ? '' : this.personViolateType,
        startDate: this.date.length == 0 ? '' : this.date[0],
        endDate: this.date.length == 0 ? '' : this.date[1],
        page: this.pageNum,
        size: this.pageSize
      }
      this.tableLoading = true
      this.$http.getPersonViolationList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 处理按钮
    onClickDeal (row) {
      this.selectVisitedArr.push(row)
      if (this.currentTabIndex == 0) {
        this.violationAlarmDealVisible = true
        this.violationAlarmDealId = row.carViolationId
        this.violationAlarmDealType = 'carViolation'
      } else {
        this.violationAlarmDealVisible = true
        this.violationAlarmDealId = row.dlbjViolationId
        this.violationAlarmDealType = 'personViolation'
      }
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            carViolationId: this.violationId,
            ruleTypeName: this.carViolateType == 0 ? '' : this.carViolateType,
            carTypeId: "",
            carTypeName: "",
            carId: this.carNumberId,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
            gridId: "",
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.carViolationId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        case 1:
          data = {
            dlbjViolationId: this.violationId,
            personTypeId: "",
            personTypeName: "",
            personId: this.personId,
            violationTypeName: this.personViolateType == 0 ? '' : this.personViolateType,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.dlbjViolationId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        default:
          break;
      }
      return data
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
      
    },
    // 点击查看处理记录
    processingRecord (row) {
      this.selectVisitedArr.push(row)
      switch (this.currentTabIndex) {
        case 0:
          this.violationAlarmDealListId = row.carViolationId
          break;
        case 1:
          this.violationAlarmDealListId = row.dlbjViolationId
          break;
        default:
          break;
      }
      this.violationAlarmDealListVisible = true
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .table {
      margin-top: 16px;
    }
  }
}
</style>